@import "../../utils/includes.scss";

// ========
// Fonts
// ========

$fontFamily: 'Open Sans', arial;
$boldFontFamily: 'Open Sans', arial;
$boldFontWeight: 700;
$titleFontFamily: $boldFontFamily;
$titleFontWeight: $boldFontWeight;
$isUppercase: uppercase;

// ========
// Colors
// ========

$textColor: #333333;
$linkColor: #00a2fd;
$labelColor: #aaaaaa;
$formColor: #333333;
$utilColor: #00a2fd;
$priceColor: #ff2841;
$lightColor: #aaaaaa;
$btnColorAlpha: #ffffff;
$btnColorBeta: #ffffff;
$btnColorStep: #ffffff;
$titleColor: #16181f;
$titleSecondaryColor: #ffffff;
$subtitleColor: #333333;
$subtitleSecondaryColor: #ffffff;
$successColor: #2DB253;
$warningColor: #f7941d;
$errorColor: #ff3526;

$bgPrimaryColor: #ffffff;
$bgSecondaryColor: #f2f2f2;

$bgBtnAlpha: #e7253c;
$colorBtnAlpha: #fff;

$bgBtnBeta: #ffffff;
$colorBtnBeta: #555555;

$colorBtnOutlineAlpha: #555555;
$colorBtnOutlineBeta: #00a2fd;

$paddingButtonPrimary: 16px 14px;
$paddingButtonSecondary: 11px 15px;
$borderRadiusButton: 5px;

$borderRadiusForm: 0px;
$borderColorForm: #000;
$borderSizeForm: 1px solid;

$calculatorTabsColor: #16181e;
$calculatorTabsTextColor: #dedede;
$calculatorCloseTabs: #505050;
$calculatorCloseTabsColor: #686869;
$calculatorMainColor: #ffffff;
$calculatorButton: #ff1b43;

$garageBackground: #ffffff;
$garageTextColor: #333333;
$garageCarNoteColor: #f8f8f8;

$bgTopbarLogin: #21232e;
$colorTopbarLogin: #c8c9cb;

$progressBarTitleBG: #00426b;
$progressBarTitleColor: #ffffff;
$progressBarTitleCloseBG: #ffffff;
$progressBarTitleCloseColor: #00426b;
$progressBarCloseButtonBG: #005e99 ;
$progressBarCloseButtonColor: #ffffff;

$paymentCalculatorClosedBG: #171921;
$paymentCalculatorClosedColor: #e8e8e8;
$paymentCalculatorPanelBG: #21232E;
$paymentCalculatorTermWrapperBG: #363a4d;
$paymentCalculatorTermWrapperColor: #aaaaaa;
$paymentCalculatorTermSelectedWrapperBG: #ffffff;
$paymentCalculatorTermSelectedColor: #ffffff;
$paymentCalculatorTermBG: #252835;
$paymentCalculatorTermColor: #aaaaaa;

// ========
// Styles
// ========
.widget-sr{
    @include clearfix;
    width: 100%;
    &.dealer__portland-street-honda{
        font-family: $fontFamily;
        font-size: 14px;
        color: $textColor;
        background-color: $bgPrimaryColor;
        &.Checkout{
            background-color: $bgSecondaryColor
        }
        .listing-tile .listing-tile-drivePowerTrains{
            color: $textColor;
        }
        #button-register.sr-button-1{
            background: #555;
            border: 1px solid #555 !important;
            &:hover{
                background: #fff !important;
                color: #555 !important;
                border: 1px solid #fff !important;
            }
         }
        .sr-progressBar-bg-title{
            background: #e3173e !important;
        }
        .sr-button-2{
            background: $bgBtnBeta !important;
            color: $colorBtnBeta !important;
            border: 1px  solid $colorBtnBeta !important;
            &:hover{
                border: 1px  solid $colorBtnBeta !important;
                background: $colorBtnBeta !important;
                color: #fff !important;
            }
        }
        // **********************
        // Header B&B
        // **********************
        @include headerBuildBuy;

        // **********************
        // Barre de progression
        // **********************
        @include progressBar;

        // **********************
        // Formulaires
        // **********************
        @include forms;

        // **********************
        // Par sections
        // **********************
        @include paymentCalculator;
        @include checkout;
        @include sidebarsummary;
        @include login;
        @include garage;
        @include protections;

        @include titles;
        @include buttons;
        @include links;
        @include generics;
        @include baseline;
    }
}
@include breakpoint(mobile){
    .widget-sr{
        &.dealer__portland-street-honda{
            .vehicle-tile-rebate-single{
                background: $bgBtnAlpha;
                .is-price{
                    color: $colorBtnAlpha;
                }
                span{
                    color: $colorBtnAlpha;
                }
            }
        }
    }
}